import { EColors } from 'common/styles'
import styled, { css } from 'styled-components'
import { TButton } from './types'
export const ImageArrow = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  fill: ${EColors.white};
  transition: all 0.2s ease;
  @media screen and (max-width: 999px) {
    width: 24px;
    height: 24px;
    fill: ${EColors.primary_500};
  }

`;
export const Button = styled.button<TButton>`
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 100px;
  cursor: pointer;
  z-index: 10;
  background-color: transparent;
  border: 1px solid;
  border-color:${({ disabled }) => disabled === false ? `${EColors.white}` : `${EColors.neutral_600}`}; 
  transition: all 0.2s ease;

  @media screen and (max-width: 999px) {
    border-color: ${EColors.primary_500}; 
  }

  &:hover,
  &:active {
    border-color: transparent;
    background-color: ${EColors.white};
    @media screen and (max-width: 999px) {
     background-color: ${EColors.primary_500};
  }
  }
   transform: ${({ position }) =>
    position === "left" ? "rotateZ(0deg)" : "rotateZ(-180deg)"};
  &:hover ${ImageArrow}, &:active ${ImageArrow} {
    fill: ${EColors.primary_500};
    @media screen and (max-width: 999px) {
      fill: ${EColors.white};
    }
  }

`;
