import { Svg } from 'common/assets'
import { FlexWrapperBrand } from 'modules/Footer/styled'
import { StyledCopyRight } from './styled'

export const BrandEntities = () => {
 
  return (
    <>
      <FlexWrapperBrand>
        <img src={Svg.Logo} />
        <StyledCopyRight />
      </FlexWrapperBrand>
    </>
  )
}
