import { Svg } from 'common/assets'
import { useResolution } from 'common/hooks'
import { GeneralMenu } from 'modules/GeneralMenu'
import { LoadingApps } from 'modules/LoadingApps'
import { memo } from 'react'
import { Comopnent } from './components'
import { Container, StyledLogo } from './styled'

export const Header = () => {
  const { isMinScreen768, isMinScreen1000 } = useResolution()
  return (
    <>
      <Container>
        <StyledLogo src={Svg.Logo} alt="logo" />
        {isMinScreen1000 && <GeneralMenu />}
        {isMinScreen768 && <LoadingApps />}
        <Comopnent.RightMenu />
      </Container>
    </>
  )
}
