import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";


export const LoadAppWrapper = styled.div`
display:flex;
flex-direction:column;
width: 100%;
max-width: 526px;
@media screen and (max-width: 999px) {
align-items:center;
}
`

export const StyledTitle = styled(Styled.PBody2SB)`
margin-bottom: 8px;
@media screen and (max-width: 550px) {
    text-align: center;
}
`
export const StyledButtonWrapper = styled.div`
display:flex;
align-items:center;
@media screen and (max-width: 999px) {
    justify-content:center;
    gap:8px;
 }
 @media screen and (min-width: 1000px) {
    gap:16px;
 }
`

export const ImageLoading = styled.svg`
width:24px;
height: 24px;
fill:${EColors.white};
transition:all 0.3s linear;
`

export const SpanText = styled.p`
font-size: 18px;
font-weight: 400;
line-height: 144%;
color: ${EColors.white};
transition:all 0.3s linear;
`
export const ButtonLoading = styled.button`
display: flex;
width: 176px;
height: 44px;
padding: 0px 12px;
justify-content: center;
align-items: center;
gap: 8px; 
outline: none;
cursor: pointer;
border-radius: 60px;
border: 1px solid ${EColors.neutral_800};
background: ${EColors.neutral_800};
transition:all 0.3s linear;
@media screen and (max-width: 550px) {
  width: 167px;
}
@media screen and (max-width: 374px) {
  max-width: 100%;
}
&:hover > ${ImageLoading}{
    fill:${EColors.primary_500};
}
&:hover > ${SpanText}{
    color:${EColors.primary_500};
}
`
