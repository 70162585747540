import { ELocales } from 'common/locales'
import { Service } from 'common/services'
import { EColors } from 'common/styles'
import { Styled } from 'components/Styled'
import { useTranslation } from 'react-i18next'
import { TranslationButton } from './styled'

export const Lang = () => {
  const { t, i18n } = useTranslation()

  const ukrColor =
    i18n.language === ELocales.uk ? EColors.neutral_800 : EColors.neutral_400 

  const engColor =
    i18n.language === ELocales.en ? EColors.neutral_800 : EColors.neutral_400

  const ukrWeight = i18n.language === ELocales.uk ? '500' : '400'
  const engWeight = i18n.language === ELocales.en ? '500' : '400'

  const onUkrClick = () => {
    Service.Localization.setLocale(ELocales.uk)
  }

  const onEngClick = () => {
    Service.Localization.setLocale(ELocales.en)
  }

  return (
    <>
      <Styled.FlexWrapper gap={4}>
        {/* Ukr */}
        <TranslationButton onClick={onUkrClick}>
          <Styled.PBody2 color={ukrColor} fontWeight={ukrWeight}>
            {t('uk')}
          </Styled.PBody2>
        </TranslationButton>
        {/* Slash */}
        <Styled.PBody1 color={EColors.neutral_400}>/</Styled.PBody1>
        {/* Eng */}
        <TranslationButton onClick={onEngClick}>
          <Styled.PBody2 color={engColor} fontWeight={engWeight}>
            {t('eng')}
          </Styled.PBody2>
        </TranslationButton>
      </Styled.FlexWrapper>
    </>
  )
}
