import BookBackground from './sources/bookBackground.png'
import BookBg from './sources/bookBg.jpg'
import BookBackgroundWeb from './sources/bookBackground.webp'
import BookBackgroundBig from './sources/bookBackground@2x.png'
import BookBackgroundWebBig from './sources/bookBackground@2x.webp'
import AboutUs1 from './sources/about_us_1.png'
import AboutUs2 from './sources/about_us_2.png'
import Libraria from './sources/libraria.png'
import MonsPins from './sources/mons_pins.png'
import ParaDjanow from './sources/para_djanow.png'
import BaboGarden from './sources/babo_garden.png'
import Centaur from './sources/centaur.png'
import BgMons from './sources/bgMons.png'
import Swiper1 from './sources/bg_restaron.png'
import Swiper2 from './sources/swiper.png'
import Swiper3 from './sources/swiper1.png'
import ModalBg from './sources/modal_bg.png'
import App from './sources/app_store.png'
import Google from './sources/google_play.png'

export const Png = {
  BookBackground,
  BookBackgroundWeb,
  BookBackgroundBig,
  BookBackgroundWebBig,
  BookBg,
  AboutUs1,
  AboutUs2,
  Libraria,
  MonsPins,
  ParaDjanow,
  BgMons,
  Centaur,
  BaboGarden,
  Swiper1,
  Swiper2,
  Swiper3,
  ModalBg,
  App,
  Google
}
