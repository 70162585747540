import { Politics } from "modules/modals/Politics";
import { ReactNode } from "react";
import { BookProvider } from "./Book";
import { LandingProvider } from "./Landing/Landing";
import { MenuProvider } from "./Menu";
import { PoliticsProvider } from "./Politics/PoliticsContext";
import { SmoothScrollProvider } from "./SmoothScroll";

type ContextProps = {
  children: ReactNode;
};

export const Context = ({ children }: ContextProps) => {
  return (
    <>
      <LandingProvider>
        <BookProvider>
          <MenuProvider>
            <PoliticsProvider>
              <SmoothScrollProvider>{children}</SmoothScrollProvider>
            </PoliticsProvider>
          </MenuProvider>
        </BookProvider>
      </LandingProvider>
    </>
  );
};
