export const General = {
  generalPolicyPrivacy: '#',
  policyPrivacy: '#',
  instagram: 'https://www.instagram.com/monspius/',
  facebook: 'https://www.facebook.com/MonsPius ',
  googleApiKey: 'AIzaSyAfq0_75Gl5XXb9En8dlz7LdPQhftS8XH0',
  parajanov: 'https://parajanov.com.ua/',
  babogardens: 'https://babogardens.com.ua/',
  libraria: 'https://libraria.com.ua/',
  centaura:'https://centaura.com.ua/',
  phone: '0 (32) 235-60-60',
}
