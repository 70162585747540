import { FlexWrapper } from "components/Styled/Styled";
import styled from "styled-components";

export const Container = styled(FlexWrapper)`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 1199px) {
    gap: 16px;
  }

  @media screen and (max-width: 550px) {
    gap: 28px;
  }
`;
