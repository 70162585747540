import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
   @media screen and (max-width: 767px) {
    padding:0 16px;
  }
`

export const FormContainer = styled.form`
  max-width: 100%;
  width: 514px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  @media screen and (min-width: 768px) {
  width: 514px;
  }
`
