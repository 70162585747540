import { Config } from 'common/config'
import { Component } from './components'
import { Container, Content } from './styled'

export const AboutUs = () => {
  return (
    <>
      <Container id={Config.Section.aboutUs}>
        <Content>
          <Component.General />
          <Component.Statistics />
        </Content>
      </Container>
    </>
  )
}
