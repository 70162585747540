import { EColors } from "common/styles";
import { Styled } from "components/Styled";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  /* @media screen and (min-width: 1440px) {
    height: 168px;
  } */
`;

export const MottoWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1135px;
  position: relative;
  opacity: 0;
  transform: translateY(-100%);
  animation: appearence 0.4s linear 0.1s forwards;
  @keyframes appearence {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
export const MottoText = styled(Styled.H1)`
  color: ${EColors.neutral_800};
  /* z-index: 2;
  position: absolute;
  top: 0;
  left: 0; */
  @media screen and (max-width: 500px) {
    margin-bottom: 12px;
  }
  @media screen and (max-width: 1439px) {
    position: static;
    z-index: 0;
  }
`;

export const ImageBadge = styled.svg`
  position: absolute;
  max-width: 97px;
  max-height: 98px;
  top: -40%;
  left: 94%;
  fill: rgba(34, 34, 34, 0.12);
  transform: rotate(15deg);
  transition: all 0.4s linear;
  cursor: pointer;
  use {
    width: 97px;
    height: 98px;
  }
  &:hover {
    fill: ${EColors.primary_500};
    transform: rotate(-15deg);
  }
  @media screen and (min-width: 1000px) {
    top: -23%;
    left: 96%;
  }
   @media screen and (min-width: 1340px) {
    top: -10%;
    left: 100%;
  }
  @media screen and (max-width: 767px) {
    width: 57px;
    top: -100%;
    left: 88%;
  }
   @media screen and (max-width: 500px) {
    
    top: -55%;
    left: 84%;
  }
 
  @media screen and (min-width: 768px) and (max-width: 999px) {
    /* top: -22%;
    left: 96%; */
    /*commit if there is block LoadApp */
    top: -36%;
    left: 90%;
  }
`;
