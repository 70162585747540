import { EColors } from "common/styles";
import styled from "styled-components";

export const StyledFooter = styled.footer`
  width: 100%;
  height:340px;
  background-color: ${EColors.bg_light};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 73px 72px 124px;
  
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 52px 72px 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 52px 16px 40px;
  }
  @media screen and (max-width: 1439px) {
    height: auto;
  }
`;
export const StyledFooterMenu = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1200px) {
    gap: 40px;
  }

`;
export const FlexWrapperBrand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    align-items: center;
    img {
      width: 85px;
    }
  }
`;

