import axios from 'axios'
import moment from 'moment'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { BookContext, EBookStatus } from 'common/contexts/Book'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { Input } from 'components/input'
import { Styled } from 'components/Styled'
import { useContext, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { Container, FormContainer } from './styled'
import { TBookProps } from './types'
import { LandingContext } from 'common/contexts/Landing/Landing'
import { baseURL } from 'models'

enum EInput {
  name = 'name',
  phone = 'phone',
  guests = 'guests',
  date = 'date',
  time = 'time',
}

const phoneRegExp = /^([+]?[0-9\s-\(\)]{3,25})*$/i

const initialValues: TBookProps = {
  name: '',
  phone: '',
  guests: '',
  date: '',
  time: '',
  lending: true,
}
export const Form = () => {
  const { t } = useTranslation()
  const { setStatus } = useContext(BookContext)
  const [isVisible, setIsVisible] = useState(false)
  const { landing } = useContext(LandingContext)
  const dateValidation = moment().format('DD-MM-YYYY')

  /*create validation BookSchema*/
  const BookSchema = Yup.object({
    name: Yup.string()
      .min(2)
      .required(`${t('field-required')}`),
    phone: Yup.string()
      .required(`${t('field-required')}`)
      .matches(phoneRegExp, `${t('invalid-phone')}`)
      .min(5)
      .max(20),
    guests: Yup.number()
      .min(1, `${t('invalid-count')}`)
      .max(50, `${t('max-count')}`)
      .required(`${t('field-required')}`),
    date: Yup.date()
      .required(`${t('field-required')}`)
      .nullable()
      .test(
        'is-future',
        `${t('invаlid-date')}`,
        /* eslint-disable */
        function (value) {
          if (!value) {
            return
          }
          const now = moment.utc().subtract(24, 'hours')
          const selectedDate = moment(value).utc()
          return selectedDate >= now
        },
      ),
    time: Yup.string()
      .required(`${t('field-required')}`)
      .test(
        'is-future',
        `${t('past-date')}`,
        /* eslint-disable */
        function (value) {
          const { date } = this.parent
          const selectedDateTime = new Date(date)
          const timeFrom = selectedDateTime.setHours(12)
          const timeTo = selectedDateTime.setHours(21)
          const selectHours = selectedDateTime.setHours(
            parseInt(value.split(':')[0]),
          )
          const availableTime = selectHours >= timeFrom && selectHours <= timeTo
          selectedDateTime.setHours(parseInt(value.split(':')[0]))
          selectedDateTime.setMinutes(parseInt(value.split(':')[1]))
          const validity = selectedDateTime > new Date() && availableTime
          return validity
        },
      ),
  })

  const onSubmitData = (
    values: TBookProps,
    /* eslint-disable */
    actions: any,
  ) => {
    /*create date field*/
    const time = moment(`${values.time}`, 'HH:mm').format('HH:mm')
    const date = moment(`${values.date}`, 'YYYY-MM-DD').format('YYYY-MM-DD')
    const dateData = `${date}` + 'T' + `${time}` + ':00'

    /*create booking object*/
    const booking = {
      name: values.name,
      phone: values.phone,
      guests: Number(values.guests),
      date: dateData,
      restaurant: landing.restaurant,
      lending: true,
    }

    /*send booking datas*/
    axios({
      method: 'POST',
      url: `${baseURL}/api/booking`,
      data: booking,
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then(response => {
        const result = response.data
        {
          result && setStatus(EBookStatus.success)
        }
        console.log(result)
        return result
      })
      .catch(error => {
        const errorRes = error.response.data;
        console.log(errorRes);
        if(errorRes.message === "Can not book this day"){
          setStatus(EBookStatus.busy);
        }else{
        setStatus(EBookStatus.error);
        }
      })

    /*reset values inputs*/
    actions.resetForm({ values: initialValues })
  }

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }, [isVisible])

  return (
    <>
      <Container style={{ opacity: isVisible ? 1 : 0 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={BookSchema}
          onSubmit={onSubmitData}>
          {({ handleSubmit, handleChange,setFieldValue, values }) => (
            <FormContainer onSubmit={handleSubmit}>
              <Fade cascade triggerOnce delay={400}>
                <Styled.H2 align="center" color={EColors.primary_500}>
                  {t('book.table.title')}
                </Styled.H2>

                <Styled.FlexWrapper direction="column" gap={48}>
                  <Fade
                    cascade
                    triggerOnce
                    direction="up"
                    delay={800}
                    duration={500}
                    damping={0.5}
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Input.InputField
                      onChange={handleChange}
                      name={EInput.name}
                      placeholder={t('name') as string}
                      value={values.name}
                    />
                    <Input.CustomInputField
                      mask="+38(099) 999-99-99"
                      name={EInput.phone}
                      placeholder={t('phone') as string}
                      onChange={e => {
                        const value = e.target.value || ''
                        const changedValue = value
                          .replace(/\)/g, '')
                          .replace(/\(/g, '')
                          .replace(/-/g, '')
                          .replace(/ /g, '')
                        setFieldValue('phone', changedValue)
                      }}
                    />

                    <Input.InputField
                      onChange={handleChange}
                      name={EInput.guests}
                      placeholder={t('guests.total') as string}
                      type={'number'}
                      value={values.guests}
                    />

                    <Styled.FlexWrapper gap={48} width="100%">
                      <Input.InputField
                        onChange={handleChange}
                        name={EInput.date}
                        placeholder={t('date') as string}
                        type={'date'}
                        value={values.date}
                      />
                      <Input.InputField
                        onChange={handleChange}
                        name={EInput.time}
                        placeholder={t('time') as string}
                        type={'time'}
                        value={values.time}
                      />
                    </Styled.FlexWrapper>

                    <Button.Ellipse type="submit">{t('book')}</Button.Ellipse>
                  </Fade>
                </Styled.FlexWrapper>
              </Fade>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </>
  )
}
