import { Svg } from 'common/assets'
import { ButtonPositionPallete } from './pallete'
import { Button, ImageArrow } from './styled'
import { PositionProps } from './types'

export const Position = ({
  position = 'top',
  disabled = false,
  pallete = ButtonPositionPallete.standart,
  size = '48px',
  ...props
}: PositionProps) => {
  return (
    <>
      <Button
        {...props}
        position={position}
        disabled={disabled}
        pallete={pallete}
        size={size}>
        <ImageArrow>
          <use href={Svg.sprite + "#icon-arrow"} />
        </ImageArrow>
      </Button>
    </>
  )
}
