import { EColors } from 'common/styles'
import { FONT } from 'common/utils'
import styled, { css } from 'styled-components'
import { TStyledButton, TStyledButtonContainer } from './types'

// Filled ellipse button
export const FilledEllipse = styled.img<TStyledButton>`
  /* Hover color */
  filter: ${({ colorPallete }) => colorPallete?.hoverButton};
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  left: 0;
  top: 0;

  transition: all 0.2s ease;

  /* Active color */
  &:active {
    filter: ${({ colorPallete }) => colorPallete?.activeButton};
  }
`

// Ellipse button
export const EllipseButton = styled.img<TStyledButton>`
  filter: ${({ disabled, colorPallete }) =>
    disabled ? EColors.filter_neutral_600 : colorPallete?.mainButton};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  transition: all 0.2s ease;

  ${({ disabled, colorPallete }) =>
    !disabled &&
    css`
      &:hover {
        filter: ${colorPallete?.hoverButton};
      }

      &:active {
        filter: ${colorPallete?.activeButton};
      }
    `}
`

const ORIGIN_WIDTH = 244
const ORIGIN_HEIGHT = 56
const RATIO = ORIGIN_HEIGHT / ORIGIN_WIDTH

export const ButtonContainer = styled.button<TStyledButtonContainer>`
  background-color: transparent;
  border: none;
  position: relative;
  width: ${({ width }) => width};
  height: ${({ width }) => parseInt(width) * RATIO}px;
  transition: all 0.2s ease;

  /* disabled color text*/
  ${FONT({ weight: '500', color: EColors.neutral_600, size: '16px' })}

  @media screen and (max-width:767px) {
    font-size: 14px;
    width:212px;
  }

  ${({ disabled, colorPallete }) =>
    !disabled &&
    css`
      /* main color text*/
      color: ${colorPallete?.mainText};
      cursor: pointer;

      &:hover > ${FilledEllipse} {
        opacity: 1;
      }

      &:active > ${FilledEllipse} {
        opacity: 1;
      }
      /* Hover text */
      &:hover {
        color: ${colorPallete?.hoverText};
      }

      /* Active text */
      &:active {
        color: ${colorPallete?.activeText};
      }
    `}
`

export const ContantContainer = styled.div`
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
