import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Section = styled.section`
  width: 100%;
  background-color: ${EColors.bg_light};
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 140px 72px;

  @media screen and (max-width: 1000px) {
    padding: 60px 72px;
  }

  @media screen and (max-width: 550px) {
    padding: 60px 16px;
  }
`
