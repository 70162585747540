import axios from 'axios'
import { Modal } from 'modules/modals'
import { baseURL,  initialPolitics,  TPolitics } from 'models'
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from 'react'

type PoliticsProps = {
    children: ReactNode
}

type PoliticsContextProps = {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
   
    politics: TPolitics
    setPolitics: Dispatch<SetStateAction<TPolitics>>
}



export const PoliticsContext = createContext<PoliticsContextProps>({
    isOpen: false,
    setIsOpen: () => { },

    politics: initialPolitics,
    setPolitics: () => { },
    
})


export const PoliticsProvider = ({ children }: PoliticsProps) => {
    const [politics, setPolitics] = useState<TPolitics>(initialPolitics)
    const [isOpen, setIsOpen] = useState(false)


    const value = {
        isOpen,
        setIsOpen,
    
        politics,
        setPolitics,
    }


    const onBackClick = () => {
        setIsOpen(!isOpen);
    };
 

    return (
        <PoliticsContext.Provider value={value} >
            {children}
            {isOpen && <Modal.Politics onBackClick={onBackClick} politics={politics} />}  
        </PoliticsContext.Provider>
    )
}
