import { Png } from 'common/assets'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  align-self: center;
  gap: 48px;
  padding: 0px 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  align-items: flex-start;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 0px 16px;
    gap: 24px;
  }
`

export const ContantContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;

  @media screen and (max-width: 999px) {
    flex-direction: column;
  }
`

export const LeftImage = styled.img`
  width: 100%;
  max-width: 636px;
  margin:0 auto;
  
  @media screen and (max-width: 999px) {
    max-width: 100%;
    margin-bottom:24px;
  }
`

export const RightImage = styled.img`
  width: 100%;
  max-width: 636px;
  margin:0 auto;
  
  @media screen and (max-width: 999px) {
    max-width: 100%;
  }
`

export const BookContainer = styled.div`
  display: flex;
  width: 50%;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 999px) {
    width: 100%;
  }
`

export const AboutContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 32px;
  align-items: flex-end;

  @media screen and (max-width: 999px) {
    width: 100%;
    flex-direction: column-reverse;
    gap: 44px;
    align-items: center;
  }
`
