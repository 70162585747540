import { Svg } from 'common/assets'
import { BookContext } from 'common/contexts/Book'
import { EColors } from 'common/styles'
import { Button } from 'components/buttons'
import { Styled } from 'components/Styled'
import { useContext, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { Container, FormContainer, StatusImage, TextInfo } from './styled'

export const Done = () => {
  const { t } = useTranslation()
  const { setIsOpen } = useContext(BookContext)
  const [isVisible, setIsVisible] = useState(false)

  const onBackClick = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true)
    }, 0)
  }, [isVisible])
  return (
    <>
      <Container>
        <FormContainer>
          <Fade triggerOnce>
            <StatusImage src={Svg.Success} />

            <Styled.H2 color={EColors.neutral_800}>{t('thanks')}</Styled.H2>

            <TextInfo>{t('book.success.text')}</TextInfo>

            <Button.Ellipse onClick={onBackClick}>{t('back')}</Button.Ellipse>
          </Fade>
        </FormContainer>
      </Container>
    </>
  )
}
