import { Styled } from "components/Styled";
import { useTranslation } from "react-i18next";
import {
  Container,
  Link,
  PolicyPrivacyContainer,
  StyledCopyRight,
  StyledGeneralMenu,
  StyledWrapper,
} from "./styled";
import { useContext, useEffect, useState } from "react";
import { PoliticsContext } from "common/contexts/Politics/PoliticsContext";
import { baseURL } from "models";
import axios from "axios";
import { politics } from "./data";
import { LoadingWrapperApps } from "../LoadingWrapperApps";

export const General = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const { isOpen, setIsOpen, setPolitics } = useContext(
    PoliticsContext
  );

  isOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "inherit");
  
  const handleOpenModalAgreement = () => {
    setIsOpen(!isOpen);
    setName("");
  };
  
  const handleOpenModalPolitics = () => {
    setIsOpen(!isOpen);
    setName("privacyPolicy");
  };

  const fetchPolitics = async (currentName: string) => {
    try {
      await axios
        .get(`${baseURL}/api/variables/${currentName}`)
        .then((response) => setPolitics(response.data));
    } catch (e) {
      throw Error;
    }
  };

  useEffect(() => {
    if (name !== "") {
      fetchPolitics(name);
    } else {
      setPolitics(politics);
    }
  }, [name, isOpen]);

  return (
    <>
      <Container>
        <StyledWrapper>
          <StyledGeneralMenu />
          <LoadingWrapperApps />
        </StyledWrapper>
        
        <PolicyPrivacyContainer>
          <Link onClick={handleOpenModalAgreement}>
            <Styled.PBody2>{t("general.policy.privacy")}</Styled.PBody2>
          </Link>
          <Link onClick={handleOpenModalPolitics}>
            <Styled.PBody2>{t("policy.privacy")}</Styled.PBody2>
          </Link>
          <StyledCopyRight />
        </PolicyPrivacyContainer>
      </Container>
    </>
  );
};
