import { Styled } from 'components/Styled'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Settings } from 'react-slick'
import SlickSlider from 'react-slick'

import {
  Container,
  ContentContainer,
  LeftButtonContainer,
  RightButtonContainer,
  Slide,
  StyledSlider,
  Title,
} from './styled'
import { Button } from 'components/buttons'
import { ButtonPositionPallete } from 'components/buttons/Position'
import { useInViewPort, useResolution } from 'common/hooks'
import { slides } from './slides'

export const Slider = () => {
  const { t } = useTranslation()
  const slider = useRef<SlickSlider>(null)
  const lastSlide = useRef<HTMLAnchorElement>(null)
  const firstSlide = useRef<HTMLAnchorElement>(null)
  const { isMinScreen1000 } = useResolution()

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // swipe: !isMinScreen1000,
    // swipeToSlide: !isMinScreen1000,
    arrows: false,
    centerMode: false,
    variableWidth: true,
  }

  const { isInHorizontalViewPort: isFirstElementVisible } = useInViewPort({
    target: firstSlide,
  })
  const { isInHorizontalViewPort: isLastElementVisible } = useInViewPort({
    target: lastSlide,
  })

  const onLeftButton = () => {
    slider.current?.slickPrev()
  }

  const onRightButton = () => {
    slider.current?.slickNext()
  }

  const getSlideRef = (index: number) => {
    // If last slide
    if (index === slides.length - 1) return lastSlide
    // If first slide
    if (index === 0) return firstSlide

    // If another slides
    return null
  }

  return (
    <>
      <Container>
        {/* Title */}
        <ContentContainer>
          <Title>{t('another.family.restaurants')}</Title>
        </ContentContainer>

        {/* Slider */}
        <div style={{ width: '100%', position: 'relative' }}>
          <StyledSlider {...settings} ref={slider}>
            {slides.map((slide, index) => (
              // Slide
              <Slide
                key={index}
                ref={getSlideRef(index)}
                href={slide.src}
                target="_blank">
                <Styled.FlexWrapper
                  height="100%"
                  align={index % 2 === 0 ? 'flex-end' : 'flex-start'}>
                  <img src={slide.img} />
                </Styled.FlexWrapper>
              </Slide>
            ))}
          </StyledSlider>

          {/* Left button */}
          {!isFirstElementVisible && (
            <LeftButtonContainer>
              <Button.Position
                position="left"
                pallete={ButtonPositionPallete.white}
                onClick={onLeftButton}
              />
            </LeftButtonContainer>
          )}

          {/* Rigth button */}
          {!isLastElementVisible && (
            <RightButtonContainer>
              <Button.Position
                position="right"
                pallete={ButtonPositionPallete.white}
                onClick={onRightButton}
              />
            </RightButtonContainer>
           )} 
        </div>
      </Container>
    </>
  )
}
