import { Svg } from 'common/assets'
import { useTranslation } from 'react-i18next'
import {
  Container,
  ImageBadge,
  MottoText,
  MottoWrapper
} from './styled'

export const Motto = () => {
  const { t } = useTranslation()
  const language = localStorage.getItem("Localization") ? localStorage.getItem("Localization") : 'uk'

  return (
    <>
       <Container>
        <MottoWrapper style={{ maxWidth: language === 'uk' ? '1135px' : '1000px' }}>
          <MottoText>{t('motto.first')}</MottoText>
          <ImageBadge>
            <use href={Svg.sprite + "#icon-badge"} />
          </ImageBadge>
        </MottoWrapper>
      </Container>
    </>
     
  )
}
