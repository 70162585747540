import { Modal } from 'modules/modals'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { EBookStatus } from './types'

type BookProps = {
  children: ReactNode
}

type BookContextProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  status: EBookStatus
  setStatus: Dispatch<SetStateAction<EBookStatus>>
}

export const BookContext = createContext<BookContextProps>({
  isOpen: false,
  setIsOpen: () => {},
  status: EBookStatus.book,
  setStatus: () => {},
})

export const BookProvider = ({ children }: BookProps) => {
  const initialIsOpen =
    new URLSearchParams(window.location.search).get('modal') === 'reservation'
  const [isOpen, setIsOpen] = useState(initialIsOpen)
  const [status, setStatus] = useState<EBookStatus>(EBookStatus.book)

  const value = {
    isOpen,
    setIsOpen,
    status,
    setStatus,
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const modalParam = urlParams.get('modal')
    if (modalParam === 'reservation') {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    const url = new URL(window.location.href)
    const currentParam = url.searchParams.get('modal')

    if (isOpen && currentParam !== 'reservation') {
      url.searchParams.set('modal', 'reservation')
      window.history.pushState({}, '', url)
    } else if (!isOpen && currentParam) {
      url.searchParams.delete('modal')
      window.history.pushState({}, '', url)
    }
  }, [isOpen])

  useEffect(() => {
    setStatus(EBookStatus.book)
  }, [isOpen])

  return (
    <>
      <BookContext.Provider value={value}>
        {children}
        {isOpen && <Modal.Book />}
      </BookContext.Provider>
    </>
  )
}
