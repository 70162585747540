import { EColors } from 'common/styles'
import styled from 'styled-components'

export const Container = styled.header`
  width: 100%;
  padding: 16px 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${EColors.bg_light};

@media screen and (max-width: 1199px) {
    padding: 16px;
  }

`
export const StyledLogo = styled.img`
   @media screen and (max-width: 1199px) {
    width: 85px;
   }

  `
