import { diffYears } from 'common/helpers'
import { EColors } from 'common/styles'
import { IntervalNumber } from 'components/IntervalNumber'
import { RowInfo } from 'components/RowInfo'
import { Styled } from 'components/Styled'
import { useTranslation } from 'react-i18next'
import { Container, Title } from './styled'

const FOUNDED_DATE = [2010, 11, 10]
const statistics = [
  { numbers: [7,8,9], title: 'burger', info: 'burger.info' },
  { numbers: [10,12,11,13,15,18,20,19], title: 'steak', info: 'steak.info' },
  { numbers: [40,42,48,43,44,50,49,41], title: 'beer', info: 'beer.info' },
  { numbers: [95, 105, 100, 96, 98, 109, 185, 130, 210], title: 'guests', info: 'guests.info' },
  { number: diffYears(FOUNDED_DATE), title: 'years', info: 'years.info' },
]

export const Statistics = () => {
  const { t } = useTranslation()
  
  
  return (
    <>
      <Container>
        {statistics.map((statistic, index) => (
          <RowInfo
            key={index}
            Title={
              <>
                <Styled.H2 color={EColors.primary_500}>
                  {statistic.numbers && (<IntervalNumber numbers={statistic.numbers}/>)}
                  {statistic.number}
                </Styled.H2>
                <Title color={EColors.primary_500}>{t(statistic.title)}</Title>
              </>
            }
            Info={<>{t(statistic.info)}</>}
          />
        ))}
      </Container>
    </>
  )
}
