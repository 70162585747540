import { Png } from 'common/assets'
import { Config } from 'common/config'

export const slides = [
  {
    img: Png.BaboGarden,
    src: Config.General.babogardens,
  },
  {
    img: Png.ParaDjanow,
    src: Config.General.parajanov,
  },
  {
    img: Png.Libraria,
    // src: Config.General.libraria,
  },
  {
    img: Png.Centaur,
    src: Config.General.centaura,
  },

]
