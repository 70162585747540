import { AboutUs } from './AboutUs'
import { BookTable } from './BookTable'
import { Contacts } from './Contacts'
import { Main } from './Main'
import { OtherRestaurants } from './OtherRestaurants'
import { Decoration } from './Decoration'

export const Layout = {
  Main,
  BookTable,
  AboutUs,
  OtherRestaurants,
  Contacts,
  Decoration
}
